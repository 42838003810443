<template>
    <div class="baseMain">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="基础信息" name="1">
                <div class="systemSchool">
                    <el-form label-width="150px" v-if="schoolMsg != null" label-position="left">
                        <!-- <el-divider>学校基础信息</el-divider> -->
                        <!--<el-form-item label="学校ID">-->
                            <!--{{schoolMsg.school_code}}-->
                        <!--</el-form-item>-->
                        <el-form-item label="学校名称">
                            {{schoolMsg.school_name}}
                        </el-form-item>
                        <el-form-item label="学校logo">
                            <el-image style="width: 100px; height: 100px" :src="schoolMsg.logo" fit="contain"></el-image>
                        </el-form-item>
                        <el-form-item label="学校电子章">
                            <el-image style="width: 100px; height: 100px" :src="schoolMsg.receipt_seal"
                                    fit="contain"></el-image>
                        </el-form-item>
                        <el-form-item label="学校商户号">
                            {{schoolMsg.mch_id}}
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="large" @click="openImport">修改</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
            <el-tab-pane label="快捷链接" name="2">
                <div class="systemSchool">
                    <el-form label-width="150px" v-if="schoolMsg != null" label-position="left">
                        <el-form-item label="教师/学生小程序">
                            <el-image style="width: 100px; height: 100px;cursor: pointer;"
                                    src="/web_img/home.png" fit="contain"
                                    :preview-src-list="['/web_img/home.png']"></el-image>
                        </el-form-item>
                        <el-form-item label="学生缴费">
                            <el-image style="width: 100px; height: 100px;cursor: pointer;"
                                    src="/web_img/studentSearch.png" fit="contain"
                                    :preview-src-list="['/web_img/studentSearch.png']"></el-image>
                        </el-form-item>
                        <el-form-item label="教职工绑定">
                            <el-image style="width: 100px; height: 100px;cursor: pointer;"
                                    src="/web_img/teacherBind.png" fit="contain"
                                    :preview-src-list="['/web_img/teacherBind.png']"></el-image>
                        </el-form-item>
                        <el-form-item label="学校直招">
                            <el-image style="width: 100px; height: 100px;cursor: pointer;"
                                    src="/qrcode/school.png" fit="contain"
                                    :preview-src-list="['/qrcode/school.png']"></el-image>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="large" @click="openImport">修改</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
        </el-tabs>
        <el-dialog title="修改学校基础信息" v-model="importDialog" width="600px">
            <el-form :model="importForm" ref="ruleForm" label-width="auto">
                <el-form-item label="学校logo">
                    <el-upload style="width: 400px;" ref="importUpload"
                               action="https://jsonplaceholder.typicode.com/posts/"
                               :on-change="importHandleChange" :before-remove="importHandleChange"
                               :file-list="importForm.importFileList"
                               :auto-upload="false" :on-exceed="importHandleExceed"
                               list-type="picture" accept="image/png,image/jpg,image/jpeg"
                               :limit="importForm.importFileLimit">
                        <el-button type="primary">选取文件</el-button>
                        <template #tip>
                            <div class="el-upload__tip">请上传jpg、jpeg、png格式图片，大小不能超过1M</div>
                        </template>
                    </el-upload>
                </el-form-item>
                <el-form-item label="学校电子章">
                    <el-upload style="width: 400px;" ref="importUpload"
                               action="https://jsonplaceholder.typicode.com/posts/"
                               :on-change="importHandleChange2" :before-remove="importHandleChange2"
                               :file-list="importForm.importFileList2"
                               :auto-upload="false" :on-exceed="importHandleExceed2"
                               list-type="picture" accept="image/png,image/jpg,image/jpeg"
                               :limit="importForm.importFileLimit2">
                        <el-button type="primary">选取文件</el-button>
                        <template #tip>
                            <div class="el-upload__tip">请上传jpg、jpeg、png格式图片，大小不能超过300K</div>
                        </template>
                    </el-upload>
                </el-form-item>
                <el-form-item label="学校商户号" prop="batch_name">
                    <el-input v-model="importForm.mch_id "></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" size="large" @click="saveSchoolMsg">确 定</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>

    import {businessGet, businessPut} from '../../api/business.js'

    import Global from '../../Global.js'

    import {utilsUploadFile} from '../../utils/utils.js'

    export default {
        name: "systemSchool",
        data() {
            return {
                activeName: '1',
                userInfo: null,
                schoolMsg: null,
                importDialog: false,
                importForm: {
                    importFileList: [],
                    importFileLimit: 1,
                    importUploadList: [],
                    importFileList2: [],
                    importFileLimit2: 1,
                    importUploadList2: [],
                    mch_id: '',
                },
            }
        },
        created() {
            this.userInfo = JSON.parse(localStorage.getItem(Global.GlobalLocalName2));
            this.getMsg();
        },
        methods: {
            login() {
                let url = '/admin/v1/user_info?openid=' + this.userInfo.openid;
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        localStorage.setItem(Global.GlobalLocalName2, JSON.stringify(res.data.data))
                        location.reload();
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            saveSchoolMsg() {
                let requestList = {};
                let promiseList = [];
                for (let i = 0; i < this.importForm.importUploadList.length; i++) {
                    let item = this.importForm.importUploadList[i];
                    requestList['request' + i] = utilsUploadFile(item.raw, 'import', 'logo')
                    promiseList.push(requestList['request' + i]);
                }
                for (let i = 0; i < this.importForm.importUploadList2.length; i++) {
                    let item = this.importForm.importUploadList2[i];
                    requestList['request' + i] = utilsUploadFile(item.raw, 'import', 'receipt_seal')
                    promiseList.push(requestList['request' + i]);
                }
                Promise.all(promiseList).then(values => {
                    let url = '/admin/v1/base_config/' + this.userInfo.school_code;
                    let data = {
                        mch_id: this.importForm.mch_id
                    };
                    values.forEach(item => {
                        data[item.objName] = item.file_id
                    })
                    for (let i in data) {
                        if (data[i] === "") {
                            delete data[i]
                        }
                    }
                    businessPut(url, data).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.importDialog = false;
                            this.getMsg();
                            this.login();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                });
            },
            importHandleExceed2(files, fileList) {
                this.$message.warning(`当前限制选择 ` + this.importForm.importFileLimit2 + ` 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            importHandleChange2(file, fileList) {
                if (file.status == "ready") {
                    let uid = file.uid
                    const isLt = file.size / 1024 / 1024 / 0.3 <= 1;
                    if (!isLt) {
                        this.$message.warning("上传文件大小不得大于300K!");
                        for (var i = 0; i < fileList.length; i++) {
                            if (fileList[i].uid == uid) {
                                fileList.splice(i, 1)
                            }
                        }
                    }
                }
                this.importForm.importUploadList2 = fileList;
            },
            importHandleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 ` + this.importForm.importFileLimit + ` 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            importHandleChange(file, fileList) {
                if (file.status == "ready") {
                    let uid = file.uid
                    const isLt = file.size / 1024 / 1024 / 1 <= 1;
                    if (!isLt) {
                        this.$message.warning("上传文件大小不得大于1MB!");
                        for (var i = 0; i < fileList.length; i++) {
                            if (fileList[i].uid == uid) {
                                fileList.splice(i, 1)
                            }
                        }
                    }
                }
                this.importForm.importUploadList = fileList;
            },
            openImport() {
                this.importForm.importFileList = [];
                this.importForm.importFileLimit = 1;
                this.importForm.importUploadList = [];
                this.importForm.importFileList2 = [];
                this.importForm.importFileLimit2 = 1;
                this.importForm.importUploadList2 = [];
                this.importDialog = true;
            },
            handleClick(tab, event) {
                console.log(tab, event);
            },
            getMsg() {
                let url = '/admin/v1/base_config/' + this.userInfo.school_code;
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.schoolMsg = res.data.data;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            }
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

    .systemSchool {
        width: 800px;
        box-sizing: border-box;
        padding-left: 20px;
    }

</style>
